import Vue from "vue";
import axios from "axios";
import store from "@/store/index";

const apiAdmin = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.coreApi.url}/admin`,
  withCredentials: true, //true para soportar cookies de respuests
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

apiAdmin.interceptors.request.use((configuration) => {
  const currentUserAccount = Vue.prototype.$Authentication.getAccount();
  if (currentUserAccount) {
    configuration.headers.CurrentUserToken = currentUserAccount.token;
  }
  return configuration;
});

apiAdmin.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      store.dispatch(
        "notifications/addError",
        "Servicio no disponible. Vuelva a intentar"
      );
    }

    if (
      (error.response && error.response.status == 401) ||
      (error.response && error.response.status == 403)
    ) {
      store.dispatch("currentUser/logout");

      store.dispatch("notifications/addInfo", "Su sesión ha expirado");
      store.dispatch("notifications/setMiddleware");
    }

    return Promise.reject(error);
  }
);

export default {
  changeDefender(claimId, data) {
    return apiAdmin.post(`/ChangeDefender/${claimId}`, data);
  },
  changeExecutive(claimId, data) {
    return apiAdmin.post(`/ChangeExecutive/${claimId}`, data);
  },
  goBackOneState(claimId, data) {
    return apiAdmin.post(`/GoBackOneState/${claimId}`, data);
  },
  attachDocument(claimId, data) {
    return apiAdmin.post(`/AttachDocument/${claimId}`, data);
  },
  eventLog(claimId) {
    return apiAdmin.get(`/EventLog/${claimId}`);
  },
};
