<template>
  <div>
    <v-dialog v-model="dialog" max-width="900" persistent>
      <v-card>
        <v-system-bar color="primary darken-3" dark>
          Resultado de la búsqueda
          <v-spacer />
          <v-icon @click="dialog = false" :disabled="loading">mdi-close</v-icon>
        </v-system-bar>
        <v-progress-linear
          indeterminate
          color="primary darken-2"
          class="mt-5"
          height="20"
          v-if="loading"
        ></v-progress-linear>
        <div v-if="loading"><br /></div>

        <v-card-title v-if="!loading"
          >La búsqueda fue por "{{ dataSearch }}", cantidad de resultados
          {{ claims.length }}</v-card-title
        >

        <v-card-text v-if="claims.length > 0">
          <v-simple-table fixed-header height="400px" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    N° Reclamo
                  </th>
                  <th class="text-left">
                    Rut
                  </th>
                  <th class="text-left">
                    Cliente
                  </th>
                  <th class="text-left">
                    Banco
                  </th>
                  <th class="text-left">
                    Estado
                  </th>
                  <th class="text-left">
                    Fecha Creación
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in claims" :key="index">
                  <td>
                    <v-progress-linear
                      buffer-value="0"
                      stream
                      color="cyan"
                      v-if="loading"
                    ></v-progress-linear>
                    <router-link
                      to="#"
                      v-on:click.native="gotoClaim(item)"
                      v-else
                      >{{ item.internalFolio }}</router-link
                    >
                  </td>
                  <td>{{ item.rut | formatRut }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ getBankName(item.bankCode) }}</td>
                  <td>{{ item.lastActionCode | wfStatusFromAction }}</td>
                  <td>
                    <span class="one-line" v-if="item.creationDate">{{
                      item.creationDate | formatDateShort
                    }}</span>
                    <span class="one-line" v-else>{{
                      item.startDate | formatDateShort
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <pre v-if="false">
            {{ claims }}
          </pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

import claimsService from "@/services/claims";

export default {
  mixins: [baseViewMixin, parametersMixin, currentClaimSelectedMixin],
  components: {},
  watch: {
    dialog(value) {
      this.$emit("input", value);

      if (value) {
        this.search();
      }
    },
    value(value) {
      this.dialog = value;
    },
  },
  props: {
    value: {},
    dataSearch: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      claims: [],
      rowsPage: 100,
      menu: false,
      loading: false,
      isValid: false,
      dialog: this.value,
      rules: [
        (value) => !!value || "Requerido",
        (value) =>
          this.allConstants.regex.regexComments.test(value) || "No es válido",
      ],
    };
  },
  methods: {
    async gotoClaim(selectedClaimInfo) {
      this.loading = true;
      await this.loadClaimForWf(
        selectedClaimInfo.claimId,
        selectedClaimInfo.userIdentification,
        "dashboard"
      );
      this.gotoView("claimDetailInWf");
      this.loading = false;
      this.dialog = false;
    },
    getClaims(filter) {
      const sort = {
        key: "f",
        orderType: 1,
      };

      return new Promise((resolve, reject) => {
        claimsService
          .getByMacroStatus(
            this.allConstants.dashboard.groups.ddcExecutive.claimsReport
              .statuses,
            1,
            this.rowsPage,
            filter,
            sort
          )
          .then((getResult) => {
            getResult.data.claims.forEach((claim) => {
              this.claims.push(claim);
            });

            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    async search() {
      this.claims = [];
      this.loading = true;
      try {
        await this.getClaims([{ key: "if", value: this.dataSearch }]);

        await this.getClaims([
          { key: "r", value: this.dataSearch.toUpperCase() },
        ]);
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
      this.menu = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
