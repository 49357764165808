<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" class="text-center">
          <span class="font-weight-bold d-block">Fecha ingreso</span>
          {{ currentClaimSelected.claim.creationDate | formatDateShort }}
          {{ currentClaimSelected.claim.creationDate | formatTimeShort }}
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <span class="font-weight-bold d-block">Fecha actual</span>
          {{ new Date().toISOString() | formatDateShort }}
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <span class="font-weight-bold d-block">Fecha tope respuesta</span>
          <span
            v-if="
              currentClaimSelected.currentEvent &&
                currentClaimSelected.currentEvent.deadline
            "
            >{{
              currentClaimSelected.currentEvent.deadline | formatDateShort
            }}</span
          >
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <span class="font-weight-bold d-block">Plazo</span>
          <span v-if="currentClaimSelected.currentEvent">
            <span
              class="time-alert-red px-2 rounded-lg"
              v-if="currentClaimSelected.currentEvent.flag == 'Red'"
              ><v-icon dark small>mdi-clock-time-four-outline</v-icon></span
            >
            <span
              class="time-alert-green px-2 rounded-lg"
              v-if="currentClaimSelected.currentEvent.flag == 'Green'"
              ><v-icon dark small>mdi-clock-time-four-outline</v-icon></span
            >
            <span
              class="time-alert-yellow px-2 rounded-lg"
              v-if="currentClaimSelected.currentEvent.flag == 'Yellow'"
              ><v-icon small>mdi-clock-time-four-outline</v-icon></span
            >
            {{
              currentClaimSelected.currentEvent.howMuchLeft.value
                | formatDaysFromHours
            }}
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
  data() {
    return {
      historyToView: {
        show: false,
        loading: false,
        events: [],
      },
    };
  },
  methods: {
    async viewHistory() {
      this.historyToView.loading = true;
      this.historyToView.show = true;
      this.historyToView.events = [];

      this.historyToView.events = await this.loadClaimHistory();
      this.historyToView.loading = false;
    },
    async hideHistory() {
      this.historyToView.loading = false;
      this.historyToView.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
