<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          x-large
          color="primary"
          @click="open"
          v-if="showAdmin"
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
      </template>
      <span>Administración del Reclamo</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn x-large icon dark @click="dialog = false">
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-spacer />
          <div class="h1">
            Administración del Reclamo N°
            {{ currentClaimSelected.claim.internalFolio }}
          </div>

          <v-spacer />
          <template v-slot:extension>
            <v-tabs v-model="tab" centered background-color="primary" dark>
              <v-tab href="#tab-1">
                Eventos
              </v-tab>

              <v-tab href="#tab-2" v-if="showBackState">
                Retroceder Estado
              </v-tab>

              <v-tab href="#tab-3" v-if="showDefender">
                Cambiar Defensor
              </v-tab>

              <v-tab
                href="#tab-4"
                v-if="currentClaimSelected.claim.state != 'INGRESADO'"
              >
                Adjuntar Archivo
              </v-tab>

              <v-tab href="#tab-5" v-if="false">
                Información Técnica
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <Events />
          </v-tab-item>

          <v-tab-item value="tab-2">
            <BackState />
          </v-tab-item>

          <v-tab-item value="tab-3">
            <ChangeDefender />
          </v-tab-item>

          <v-tab-item value="tab-4">
            <AddDocument />
          </v-tab-item>

          <v-tab-item value="tab-5" class="ma-5">
            <v-row>
              <v-col cols="12" md="6">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>claim</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <pre>{{ currentClaimSelected.claim }}</pre>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>account</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <pre>{{ currentClaimSelected.account }}</pre>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >currentEvent</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <pre>{{ currentClaimSelected.currentEvent }}</pre>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" md="6">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >lastHumanEvent</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <pre>{{ currentClaimSelected.lastHumanEvent }}</pre>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import currentUserMixin from "@/mixins/currentUser";

import Events from "@/components/WFSite/Claims/Admin/Events";
import BackState from "@/components/WFSite/Claims/Admin/BackState";
import ChangeDefender from "@/components/WFSite/Claims/Admin/ChangeDefender";
import AddDocument from "@/components/WFSite/Claims/Admin/AddDocument";

export default {
  mixins: [currentUserMixin, currentClaimSelectedMixin],
  components: {
    Events,
    BackState,
    ChangeDefender,
    AddDocument,
  },
  data() {
    return {
      tab: null,
      dialog: false,
    };
  },
  computed: {
    showAdmin() {
      if (
        this.currentUserInfo.roles[0] ==
          this.allConstants.security.userRolesCodes.ddcExecutive &&
        this.currentClaimSelected.claim.lastStateCode != "INGRESADO" &&
        this.currentClaimSelected.claim.state != "INGRESADO" &&
        this.currentClaimSelected.claim.lastStateCode != "PENDIENTE-ADMISION"
      )
        return true;

      return false;
    },
    showBackState() {
      if (
        this.currentClaimSelected.claim.lastStateCode != "INGRESADO" &&
        this.currentClaimSelected.currentEvent != null
      )
        return true;

      return false;
    },
    showDefender() {
      if (
        this.currentClaimSelected.claim.userIdDDC !=
          "00000000-0000-0000-0000-000000000000" &&
        this.currentClaimSelected.currentEvent != null
      )
        return true;

      return false;
    },
  },
  methods: {
    open() {
      this.tab = null;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
