var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Eventos de Administración")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.processing,"loading-text":"Cargando eventos","no-data-text":"No hay eventos de administración","items-per-page":100,"hide-default-footer":"","dense":"","show-expand":"","item-key":"id","single-expand":true,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNameAction(item.type))+" ")]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateShort")(item.creationDate))+" "+_vm._s(_vm._f("formatTimeShort")(item.creationDate))+" ")]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNameUser(item.userId))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.type == 'GoBackOneState')?_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v(" Estado Anterior: "+_vm._s(_vm.wfStatusFronState( item.detail.currentEventDeleted[0].StateCode ))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v(" Nuevo Estado: "+_vm._s(_vm.wfStatusFronState(item.detail.NewStateCode))+" ")])],1):_vm._e(),(
              item.type == 'ChangeDefender' || item.type == 'ChangeExecutive'
            )?_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" Usuario Anterior: "+_vm._s(_vm.getNameUser(item.detail.OldUserId))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" Usuario Nuevo: "+_vm._s(_vm.getNameUser(item.detail.NewUserId))+" ")])],1):_vm._e(),(item.type == 'AttachDocument')?_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('pre',[_vm._v(_vm._s(item.detail))])])],1):_vm._e()],1)]}}],null,true)}),_c('Alert',{attrs:{"dialog":_vm.alert.show,"msj":_vm.alert.message},on:{"close":function($event){_vm.alert.show = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }