<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="h5">Información del reclamo</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="text-center">
          <span class="font-weight-bold d-block">Monto</span>
          {{ currentClaimSelected.claim.detail.amount | formatClassicNumber }}
          {{ getCurrencyName(currentClaimSelected.claim.detail.currencyType) }}
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <span class="font-weight-bold d-block">Productos</span>
          <span
            v-for="productCode in currentClaimSelected.claim.detail
              .productCodes"
            :key="productCode"
          >
            {{ getProductName(productCode) }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-expansion-panels multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header color="red lighten-5"
                ><span class="font-weight-bold d-block"
                  >Descripción de los hechos</span
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content color="red lighten-5">
                <p class="text-justify text-lowercase">
                  {{ currentClaimSelected.claim.detail.reason }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header color="blue lighten-5"
                ><span class="font-weight-bold d-block"
                  >Petición concreta que se somete al conocimiento y resolución
                  del defensor del cliente</span
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content color="blue lighten-5">
                <p class="text-justify text-lowercase">
                  {{ currentClaimSelected.claim.detail.request }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
  data() {
    return {
      panel: [0,1]
    }
  },
};
</script>

<style lang="scss" scoped></style>
