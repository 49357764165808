<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Documento</th>
            <th class="text-left">Fecha Creación</th>
            <th class="text-left">Archivo</th>
            <th class="text-left">Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in documents" :key="document.id">
            <td class="text-caption">{{ document.name }}</td>
            <td class="text-caption">
              {{ $d(new Date(document.creationDate), "short") }}
              {{ $d(new Date(document.creationDate), "justHour") }}
            </td>
            <td class="text-caption">
              <v-progress-linear
                buffer-value="0"
                stream
                color="cyan"
                v-if="parentIsProcessing"
              ></v-progress-linear>
              <router-link
                to="#"
                v-on:click.native="viewDocument(document)"
                v-else
              >
                <div v-if="document.fileName.length <= 30">
                  {{ document.fileName }}
                </div>
                <div v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="one-line" v-bind="attrs" v-on="on"
                        >{{ document.fileName.substring(0, 30) }}...</span
                      >
                    </template>
                    <span>{{ document.fileName }}</span>
                  </v-tooltip>
                </div>
              </router-link>
            </td>
            <td class="text-caption">
              <div
                :class="{
                  'red--text text-caption':
                    document.state ==
                      allConstants.claims.documentStatuses
                        .rejectedFirstInstance ||
                    document.state ==
                      allConstants.claims.documentStatuses
                        .rejectedSecondInstance,
                }"
              >
                {{ getDocumentStatusName(document.state) }}
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-dialog
        v-model="documentToView.show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="primary darken-3" dark>
            <v-btn
              icon
              dark
              @click="documentToView.show = false"
              :loading="documentToView.loading"
              ><v-icon>mdi-arrow-left-circle</v-icon></v-btn
            >
            <v-toolbar-title>{{ documentToView.data.name }}</v-toolbar-title>
          </v-toolbar>

          <div class="mt-3 mb-3 text-center">
            <v-btn
              class="mr-3"
              @click="documentToView.show = false"
              :loading="documentToView.loading"
              ><v-icon>mdi-arrow-left-circle</v-icon> Volver</v-btn
            >
            <v-btn
              color="green"
              class="mr-3 white--text"
              :loading="documentToView.loading"
              :disabled="documentToView.exist"
              v-if="documentToView.enableAproveAndReject"
              @click="approve"
              ><v-icon>mdi-check</v-icon> Aprobar</v-btn
            >
            <v-btn
              color="red white--text"
              :loading="documentToView.loading"
              :disabled="documentToView.exist"
              v-if="documentToView.enableAproveAndReject"
              @click="reject"
              ><v-icon>mdi-close</v-icon> Rechazar</v-btn
            >
          </div>
          <div class="mt-3 mb-3 text-center h1">
            <v-icon x-large :color="iconDownload.color">
              {{ iconDownload.icon }}
            </v-icon>
            {{ documentToView.data.fileName }}
          </div>
          <div class="mt-3 mb-3 text-center">
            <v-btn
              color="primary"
              class="ma-2 white--text"
              :loading="documentToView.loading"
              :disabled="documentToView.exist"
              @click="downloadDocument"
            >
              Descargar
              <v-icon right dark>
                mdi-cloud-download
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              class="ma-2 white--text"
              :loading="documentToView.loading"
              :disabled="documentToView.exist"
              @click="viewDocumentOnline"
              v-if="viewDocumentIframe"
            >
              Ver
              <v-icon right dark>
                mdi-eye-outline
              </v-icon>
            </v-btn>
          </div>
          <div class="mt-3 mb-3 text-center" v-if="documentToView.exist">
            <v-alert type="warning" elevation="1">
              El archivo ya no existe en nuestros registros
            </v-alert>
          </div>
          <div class="iframe-container">
            <v-progress-linear
              indeterminate
              color="cyan"
              class="ma-5"
              v-if="documentToView.loading"
            ></v-progress-linear>

            <iframe :src="file" ref="viewer" v-if="documentToView.viewOnline" />
          </div>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

import attachmentsService from "@/services/attachments";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
  props: {
    parentIsProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documentToView: {
        show: false,
        loading: false,
        exist: false,
        data: {},
        enableAproveAndReject: false,
        viewOnline: false,
      },
      downloading: false,
      file: null,
    };
  },
  computed: {
    documentsUploadedByClaimant() {
      //Aquellos documentos subidos por el cliente reclamante final. Esos son los aprobables/rechazables unicamente por el usuario con rol "ejecutivo ddc"
      let documentIds = [];

      console.log(this.currentClaimSelected.claim.events);
      const creationEvent = this.currentClaimSelected.claim.events.filter(
        (event) => event.rol == "Claimant" || event.actionCode == ""
      ); //evento de creación no tiene actionCode. entonces es blanco.

      if (creationEvent == undefined || creationEvent.length == 0) return [];

      creationEvent.forEach((event) => {
        event.documentIds.forEach((id) => {
          documentIds.push(id);
        });
      });

      const filteredDocuments = this.currentClaimSelected.claim.documents;

      /*
      const filteredDocuments = this.currentClaimSelected.claim.documents.filter(
        (document) => documentIds.includes(document.id)
      );
      */
      console.log(this.currentClaimSelected.claim.state);
      if (
        this.currentClaimSelected.claim.state == "INGRESADO" ||
        this.currentClaimSelected.claim.state == "INGRESADO-DDC" ||
        this.currentClaimSelected.claim.state == "INGRESADO-BANCO-ADMIN" ||
        this.currentClaimSelected.claim.state == "INGRESADO-BANCO-EJECUTIVO" ||
        this.currentClaimSelected.claim.lastActionCode == "REGULARIZAR"
      ) {
        return filteredDocuments.map((document) => {
          document.approvable =
            this.currentUserInfo.roles[0] ==
            this.allConstants.security.userRolesCodes.ddcExecutive;
          return document;
        });
      } else {
        return filteredDocuments.map((document) => {
          document.approvable = false;
          return document;
        });
      }
    },
    documentsNotUploadedByClaimant() {
      //Estos documentos no son aprobables/rechazables por algun usuario del WF
      let documentIds = [];
      const creationEvent = this.currentClaimSelected.claim.events.filter(
        (event) => event.rol == "Claimant" || event.actionCode == ""
      ); //evento de creación no tiene actionCode. entonces es blanco.

      if (creationEvent == undefined) return [];

      if (creationEvent == undefined || creationEvent.length == 0) return [];

      creationEvent.forEach((event) => {
        event.documentIds.forEach((id) => {
          documentIds.push(id);
        });
      });

      const filteredDocuments = this.currentClaimSelected.claim.documents.filter(
        (document) => !documentIds.includes(document.id)
      );
      return filteredDocuments.map((document) => {
        document.approvable = false;
        return document;
      });
    },
    documents() {
      const allDocuments = this.documentsUploadedByClaimant
        .concat
        //this.documentsNotUploadedByClaimant
        ();
      return allDocuments.filter((document) => !document.virtual);
    },
    iconDownload() {
      if (
        this.documentToView.data.contentType == "application/msword" ||
        this.documentToView.data.contentType ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      )
        return { color: "blue darken-2", icon: "mdi-microsoft-word" };

      if (
        this.documentToView.data.contentType == "application/vnd.ms-excel" ||
        this.documentToView.data.contentType ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
        return { color: "green darken-2", icon: "mdi-microsoft-excel" };

      if (
        this.documentToView.data.contentType ==
          "application/vnd.ms-powerpoint" ||
        this.documentToView.data.contentType ==
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      )
        return { color: "red darken-2", icon: "mdi-microsoft-powerpoint" };

      if (this.documentToView.data.contentType == "application/pdf")
        return { color: "red darken-2", icon: "mdi-file-pdf" };

      if (
        this.documentToView.data.contentType == "image/gif" ||
        this.documentToView.data.contentType == "image/jpeg" ||
        this.documentToView.data.contentType == "image/tiff" ||
        this.documentToView.data.contentType == "image/svg+xml" ||
        this.documentToView.data.contentType == "image/png"
      )
        return { color: "orange darken-2", icon: "mdi-file-image" };

      if (this.documentToView.data.contentType == "application/vnd.ms-outlook")
        return { color: "blue darken-2", icon: "mdi-microsoft-outlook" };

      return { color: "", icon: "" };
    },
    viewDocumentIframe() {
      if (
        this.documentToView.data.contentType == "application/pdf" ||
        this.documentToView.data.contentType == "image/gif" ||
        this.documentToView.data.contentType == "image/jpeg" ||
        this.documentToView.data.contentType == "image/tiff" ||
        this.documentToView.data.contentType == "image/svg+xml" ||
        this.documentToView.data.contentType == "image/png"
      )
        return true;

      return false;
    },
  },
  methods: {
    async viewDocument(documentData) {
      //Descargar y mostrar el documento
      this.documentToView.data = documentData;
      this.documentToView.show = true;
      this.documentToView.loading = true;
      this.documentToView.exist = false;
      this.documentToView.viewOnline = false;

      /*
                try {
                    const downlodResult = await attachmentsService.downlod(documentData.blobId);
                    this.documentToView.exist = true;

                    const urlCreator = window.URL || window.webkitURL;
                    const contentBlob = new Blob([downlodResult.data], {type : documentData.contentType});

                    this.$refs.viewer.src = urlCreator.createObjectURL(contentBlob);
                }
                catch {
                    this.$refs.viewer.src = "about:blank";
                    this.documentToView.exist = false;
                    this.documentToView.loading = false;
                    return; //el documento no existe. salir.
                }
                */

      /*
                Determinar si el usuario actual puede aprobar/rechazar el documento y en función de lo anterior establecer el true/false
                el valor de la variable this.documentToView.enableAproveAndReject

                Un usuario Ejecutivo-DDC lo puede aprobar y/o rechazar (en primera instancia) si actualmente esta en estado 'CARGADO'
                Un usuario Defensor-DDC lo puede aprobar y/o rechazar (en segunda instancia) si actualmente esta en estado '¿?' (Aprobado previamente en primera instancia)
                */
      this.documentToView.enableAproveAndReject = false;

      if (documentData.approvable) {
        if (
          this.currentUserInfo.roles[0] ==
          this.allConstants.security.userRolesCodes.ddcExecutive
        ) {
          if (
            this.documentToView.data.state ==
            this.allConstants.claims.documentStatuses.loaded
          ) {
            this.documentToView.enableAproveAndReject = true;
          }
        }

        if (
          this.currentUserInfo.roles[0] ==
          this.allConstants.security.userRolesCodes.ddcDefender
        ) {
          if (
            this.documentToView.data.state ==
            this.allConstants.claims.documentStatuses.approvedFirstInstance
          ) {
            this.documentToView.enableAproveAndReject = true;
          }
        }
      }

      this.documentToView.loading = false;
    },
    downloadDocument() {
      this.documentToView.loading = true;
      this.documentToView.exist = false;

      attachmentsService
        .downlod(this.documentToView.data.blobId, "claims")
        .then((downlodResult) => {
          const urlCreator = window.URL || window.webkitURL;
          const contentBlob = new Blob([downlodResult.data], {
            type: this.documentToView.data.contentType,
          });

          //this.$refs.a_download.src = urlCreator.createObjectURL(contentBlob);
          const fileURL = urlCreator.createObjectURL(contentBlob);
          //Open the URL on new Window
          window.open(fileURL);
          this.documentToView.loading = false;
        })
        .catch(() => {
          this.documentToView.exist = true;
          this.documentToView.loading = false;
        });
    },
    viewDocumentOnline() {
      this.documentToView.loading = true;
      this.documentToView.exist = false;
      this.documentToView.viewOnline = false;

      attachmentsService
        .downlod(this.documentToView.data.blobId, "claims")
        .then((downlodResult) => {
          const urlCreator = window.URL || window.webkitURL;
          const contentBlob = new Blob([downlodResult.data], {
            type: this.documentToView.data.contentType,
          });

          this.documentToView.loading = false;
          this.documentToView.viewOnline = true;
          this.file = urlCreator.createObjectURL(contentBlob);
        })
        .catch(() => {
          this.documentToView.exist = true;
          this.documentToView.loading = false;
          this.documentToView.viewOnline = false;
        });
    },
    approve() {
      this.documentToView.show = false;
      this.$emit("approveDocument", this.documentToView.data.id);
    },
    reject() {
      this.documentToView.show = false;
      this.$emit("rejectDocument", this.documentToView.data.id);
    },
  },
  async beforeMount() {
    //Identificar el resto de documentos. Esos no son aprobables/rechazables.
  },
};
</script>

<style lang="scss" scoped>
.iframe-container {
  iframe {
    width: 100%;
    height: 100vh;
  }
}
</style>
