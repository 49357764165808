<template>
  <v-card
    height="100%"
    class="pa-1 ma-1"
    elevation="0"
    v-if="posibleActions.length > 0"
  >
    <v-card-text>
      <v-form v-model="form.isValid" @submit.prevent="enter">
        <div v-if="askComments">
          <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
            Comentarios
          </div>
          <v-textarea
            v-model.trim="formFields.comentarios"
            required
            :rules="formFields.comentariosRules"
            :disabled="parentIsProcessing"
            maxlength="512"
            rows="3"
            outlined
          ></v-textarea>
        </div>
        <div>
          <v-row>
            <v-col cols="12" md="3">
              <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
                Acción
              </div>
              <v-select
                outlined
                v-model.trim="formFields.accion"
                :items="
                  posibleActions.map((action) => {
                    return { value: action.action, text: action.name };
                  })
                "
                placeholder="Seleccione"
                required
                :rules="formFields.accionRules"
                :disabled="posibleActions.length == 0 || parentIsProcessing"
                @change="prepareOtherData()"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" v-if="posibleReasons.length > 0">
              <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
                Motivo
              </div>
              <v-select
                outlined
                v-model.trim="formFields.razonAccion"
                :items="
                  posibleReasons.map((reason) => {
                    return { value: reason.code, text: reason.name };
                  })
                "
                placeholder="Seleccione"
                required
                :rules="formFields.razonAccionRules"
                :disabled="posibleReasons.length == 0 || parentIsProcessing"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" v-if="posibleValues.length > 0">
              <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
                Valores solicitados
              </div>
              <div v-for="value in posibleValues" :key="value.name">
                <v-text-field
                  v-if="value.type == 'Number'"
                  outlined
                  :required="value.required"
                  :maxlength="value.length"
                  :label="value.name"
                  :rules="validateRules(value)"
                  v-model="value.input"
                ></v-text-field>
                <v-select
                  v-if="value.type == 'Drop'"
                  outlined
                  :items="posibleValuesDrop(value)"
                  :placeholder="value.name"
                  required
                  :rules="validateRules(value)"
                  :disabled="parentIsProcessing"
                  v-model.trim="value.input"
                ></v-select>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="9"
              v-if="formFields.documentosRequeridos.length > 0"
            >
              <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
                Documentos requeridos
              </div>
              <table class="table table-striped" style="width: 100%;">
                <tbody>
                  <tr
                    v-for="documento in formFields.documentosRequeridos"
                    :key="documento.name"
                  >
                    <td>
                      {{ documento.name }} {{ documento.required ? "(*)" : "" }}
                    </td>
                    <td>
                      <v-file-input
                        v-model="documento.blob"
                        :accept="documento.allowedFileTypes.join(',')"
                        truncate-length="20"
                        :clearable="true"
                        :rules="
                          documento.required
                            ? formFields.documentosRequeridosRules
                            : formFields.documentosAlternativosRules
                        "
                        :disabled="form.processing"
                        @change="setLastSelectedDocument(documento)"
                        class="ma-0 pa-0"
                      >
                      </v-file-input>
                    </td>
                    <td>
                      <v-progress-circular
                        v-if="documento.loading"
                        indeterminate
                        color="blue-gre"
                      ></v-progress-circular>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col cols="12" md="6" v-if="ddcDefenderIsRequired">
              <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
                Defensor
              </div>
              <v-select
                outlined
                v-model.trim="formFields.defensorDDC"
                :items="
                  posibleDDCDefenders.map((defensor) => {
                    return { value: defensor.id, text: defensor.name };
                  })
                "
                placeholder="Seleccione"
                required
                :rules="formFields.defensorDDCRules"
                :disabled="
                  posibleDDCDefenders.length == 0 || parentIsProcessing
                "
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" v-if="bankExecutiveIsRequired">
              <div class="text-subtitle-1 font-weight-bold mt-3 mb-2">
                Ejecutivo
              </div>
              <v-select
                outlined
                v-model.trim="formFields.ejecutivoBanco"
                :items="
                  posibleBankExecutives.map((executive) => {
                    return { value: executive.id, text: executive.name };
                  })
                "
                placeholder="Seleccione"
                required
                :rules="formFields.ejecutivoBancoRules"
                :disabled="
                  posibleBankExecutives.length == 0 || parentIsProcessing
                "
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <div class="text-center">
          <v-btn
            type="submit"
            color="green"
            class="mr-3 text-white"
            :disabled="!form.isValid || page.loading"
            :loading="parentIsProcessing"
            ><v-icon>mdi-content-save-outline</v-icon> Grabar</v-btn
          >
        </div>
      </v-form>
      <v-dialog v-model="dialogConfirm" width="500" persistent>
        <v-card>
          <v-card-title class="grey lighten-2">
            ¿Está seguro de realizar esta acción?
          </v-card-title>
          <v-card-text class="text-center text-h5">
            <br />
            {{ nameActions(formFields.accion) }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="secundary" text @click="dialogConfirm = false">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="enterConfirm">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

import accountsService from "@/services/accounts";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
  props: {
    askComments: {
      type: Boolean,
      default: false,
    },
    parentIsProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formFields: {
        comentarios: "",
        comentariosRules: [
          (value) => value.length <= 512 || "Demasiado largo",
          (value) =>
            this.allConstants.regex.regexComments.test(value) || "No es válido",
        ],
        accion: "",
        accionRules: [(value) => !!value || "Requerido"],
        razonAccion: "",
        razonAccionRules: [(value) => !!value || "Requerido"],
        documentosRequeridos: [],
        documentosRequeridosRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) => this.isValidFileType(value) || "Formato incorrecto",
          (value) =>
            this.isValidFileSize(value) ||
            `Tamaño máximo ${this.calculateMegaBytes()} Mb`,
        ],
        documentosAlternativosRules: [
          (value) =>
            this.isValidFileName(value) ||
            "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
          (value) => this.isValidFileType(value) || "Formato incorrecto",
          (value) =>
            this.isValidFileSize(value) ||
            `Tamaño máximo ${this.calculateMegaBytes()} Mb`,
        ],
        defensorDDC: null,
        defensorDDCRules: [(value) => !!value || "Requerido"],
        ejecutivoBanco: null,
        ejecutivoBancoRules: [(value) => !!value || "Requerido"],
        values: [],
      },
      posibleActions: [],
      posibleReasons: [],
      posibleValues: [],
      posibleDDCDefenders: [],
      posibleBankExecutives: [],
      lastSelectedDocument: null,
      dialogConfirm: false,
    };
  },
  computed: {
    ddcDefenderIsRequired() {
      if (this.formFields.accion == "") return false;

      const selectedAction = this.posibleActions.find(
        (posibleAction) => posibleAction.action == this.formFields.accion
      );
      return selectedAction.requireUserDDC;
    },
    bankExecutiveIsRequired() {
      if (this.formFields.accion == "") return false;

      const selectedAction = this.posibleActions.find(
        (posibleAction) => posibleAction.action == this.formFields.accion
      );
      return selectedAction.requireUserBank;
    },
  },
  methods: {
    setLastSelectedDocument(theDocument) {
      this.lastSelectedDocument = theDocument;
    },
    calculateMegaBytes() {
      const bytes = this.lastSelectedDocument.maxFileSize;
      return (bytes / (1024 * 1024)).toFixed(1);
    },
    isValidFileName(fileInfo) {
      if (fileInfo == null) return true;

      return this.allConstants.regex.regexFileNameComplex.test(fileInfo.name);
    },
    isValidFileSize(fileInfo) {
      if (fileInfo == null) return true;

      return fileInfo.size <= this.lastSelectedDocument.maxFileSize;
    },
    isValidFileType(fileInfo) {
      if (fileInfo == null) return true;

      return (
        this.lastSelectedDocument.allowedFileTypes.filter(
          (fileType) => fileType == fileInfo.type
        ).length > 0
      );
    },
    prepareOtherData() {
      const selectedAction = this.posibleActions.find(
        (posibleAction) => posibleAction.action == this.formFields.accion
      );
      this.preparePosibleReasons(selectedAction);
      this.preparePosibleDocuments(selectedAction);
      this.preparePosibleValues(selectedAction);
      this.preparePosibleDefenders(selectedAction);
      this.preparePosibleBankExecutives(selectedAction);
    },
    preparePosibleValues(selectedAction) {
      this.posibleValues = [];

      if (!selectedAction) return;

      this.posibleValues = selectedAction.values;
    },
    preparePosibleReasons(selectedAction) {
      this.posibleReasons = [];
      this.formFields.razonAccion = "";

      if (!selectedAction) return;

      const reasons = this.$Parameters.all.find(
        (parameter) => parameter.type == "ReasonCodes"
      ).values;
      selectedAction.reasonCodes.forEach((reasonCode) => {
        const reason = reasons.find((reason) => reason.code == reasonCode);
        this.posibleReasons.push(reason);
      });
    },
    preparePosibleDocuments(selectedAction) {
      this.formFields.documentosRequeridos = [];

      if (!selectedAction) return;

      this.formFields.documentosRequeridos = selectedAction.attachments;
      this.formFields.documentosRequeridos.forEach((documento) => {
        documento.blob = null;
      });
    },
    async preparePosibleDefenders(selectedAction) {
      this.posibleDDCDefenders = [];
      this.formFields.defensorDDC = null;

      if (!selectedAction || !selectedAction.requireUserDDC) return;

      this.initSignalLoading();

      const getByRoleResult = await accountsService.getByRole(
        this.allConstants.security.userRolesCodes.ddcDefender
      );
      this.posibleDDCDefenders = getByRoleResult.data.accounts.map(
        (account) => {
          return { id: account.id, name: `${account.name} ${account.surname}` };
        }
      );

      this.stopSignalLoading();
    },
    async preparePosibleBankExecutives(selectedAction) {
      this.posibleBankExecutives = [];
      this.formFields.ejecutivoBanco = null;

      if (!selectedAction || !selectedAction.requireUserBank) return;

      this.initSignalLoading();

      const getByRoleResult = await accountsService.getByRole(
        this.allConstants.security.userRolesCodes.bankExecutive
      );
      this.posibleBankExecutives = getByRoleResult.data.accounts.map(
        (account) => {
          return { id: account.id, name: `${account.name} ${account.surname}` };
        }
      );

      this.stopSignalLoading();
    },
    enter() {
      this.dialogConfirm = true;
    },
    enterConfirm() {
      this.dialogConfirm = false;
      this.$emit(
        "executeAction",
        this.formFields.accion,
        this.formFields.razonAccion,
        this.formFields.comentarios,
        this.formFields.documentosRequeridos.filter(
          (documento) => documento.blob && documento.blob != null
        ),
        this.formFields.defensorDDC,
        this.formFields.ejecutivoBanco,
        this.posibleValues
      );
    },
    validateRules(objectValue) {
      if (objectValue.type == "Number") {
        return [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexDigits.test(value) || "No es válido",
        ];
      }

      let rules = [(value) => !!value || "Requerido"];

      return rules;
    },
    nameActions(value) {
      const action = this.posibleActions.find((pa) => pa.action == value);
      if (action == undefined || action == null) return value;

      return action.name;
    },
    posibleValuesDrop(value) {
      let result = [];
      if (value.name == "Tipología de Reclamo") {
        const typology = this.$Parameters.all.find(
          (parameter) => parameter.type == "Typology"
        ).values;
        typology.forEach((t) => {
          result.push({ value: t.code, text: t.name });
        });
      }

      return result;
    },
  },
  beforeMount() {
    //INICIO - obtener las posibles acciones sobre el reclamo en función de su etapa y estado

    if (!this.currentClaimSelected.currentEvent) return;

    this.initSignalLoading();

    const currentStageCode = this.currentClaimSelected.currentEvent.stageCode;
    const currentStatusCode = this.currentClaimSelected.currentEvent.stateCode;

    const wfStagesConfiguration = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStages"
    ).values;
    const stageConfiguration = wfStagesConfiguration.find(
      (stage) =>
        stage.code == currentStageCode &&
        stage.stateCodes.includes(currentStatusCode)
    );

    if (!stageConfiguration) {
      this.stopSignalLoading();
      return;
    }

    let currentUserRolName;

    if (
      this.currentUserInfo.roles[0] ==
      this.allConstants.security.userRolesCodes.ddcExecutive
    )
      currentUserRolName = this.allConstants.security.userRoles.ddcExecutive;

    if (
      this.currentUserInfo.roles[0] ==
      this.allConstants.security.userRolesCodes.ddcDefender
    )
      currentUserRolName = this.allConstants.security.userRoles.ddcDefender;

    if (
      this.currentUserInfo.roles[0] ==
      this.allConstants.security.userRolesCodes.bankAdministrator
    )
      currentUserRolName = this.allConstants.security.userRoles
        .bankAdministrator;

    if (
      this.currentUserInfo.roles[0] ==
      this.allConstants.security.userRolesCodes.bankExecutive
    )
      currentUserRolName = this.allConstants.security.userRoles.bankExecutive;

    if (stageConfiguration.rol !== currentUserRolName) {
      //el usuario actual no puede efectuar ninguna acción
      this.stopSignalLoading();
      return;
    }

    const wfStatusesConfiguration = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStatuses"
    ).values;
    const statusConfiguration = wfStatusesConfiguration.find(
      (status) => status.code == currentStatusCode
    );

    this.posibleActions = statusConfiguration.actions.filter(
      (action) => action.manual
    );
    this.posibleActions.forEach((action) => {
      action.attachments.forEach((attachment) => {
        attachment.blob = null;
      });
    });

    //FIN - obtener las posibles acciones sobre el reclamo en función de su etapa y estado

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped></style>
