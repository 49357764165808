<template>
  <v-card flat>
    <v-card-title>Cambiar el Defensor del Reclamo</v-card-title>
    <v-card-text>
      <v-alert
        prominent
        type="error"
        v-if="getNameDefender(currentClaimSelected.claim.userIdDDC)"
      >
        <v-row>
          <v-col class="grow h3 text-center">
            Defensor Actual es
            {{ getNameDefender(currentClaimSelected.claim.userIdDDC) }}
          </v-col>
          <v-col class="shrink">
            <v-btn color="indigo" @click="confirm">Cambiar Defensor</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>

    <v-dialog v-model="dialogConfirm" persistent width="500">
      <v-card>
        <v-card-title class="white--text text-h5 red lighten-2">
          ¿Está seguro que desea continuar?
        </v-card-title>
        <v-form v-model="form.isValid" @submit.prevent="enter">
          <v-card-text class="mt-3">
            <v-select
              outlined
              v-model.trim="defender"
              :items="
                defenders.map((d) => {
                  return { value: d.id, text: d.name };
                })
              "
              label="Defensor"
              placeholder="Seleccione un Defensor"
              required
              :rules="defendersRules"
            ></v-select>
            <v-textarea
              outlined
              label="Comentario"
              placeholder="Ingrese un comentario"
              v-model="comentarios"
              :rules="comentariosRules"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" text @click="dialogConfirm = false">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              :disabled="!form.isValid || page.loading"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <Processing :viewProcessing="processing" title="Procesando..." />
    <Alert
      :dialog="alert.show"
      :msj="alert.message"
      @close="goBack"
      :dialogPersistent="true"
    />
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import parameters from "@/mixins/parameters";

import Processing from "@/components/Processing";
import Alert from "@/components/Alert";

import serviceAdmin from "@/services/admin";

export default {
  mixins: [baseViewMixin, currentClaimSelectedMixin, parameters],
  components: {
    Processing,
    Alert,
  },
  methods: {
    goBack() {
      this.gotoView(this.currentClaimSelected.fromRouteName);
    },
    confirm() {
      this.dialogConfirm = true;
      this.defender = "";
      this.comentarios = "";
    },
    enter() {
      this.dialogConfirm = false;
      this.processing = true;

      const data = {
        newUserId: this.defender,
        comments: this.comentarios,
      };

      serviceAdmin
        .changeDefender(this.currentClaimSelected.claim.id, data)
        .then(() => {
          this.processing = false;
          this.alert.message = "El cambio se realizó exitosamente";
          this.alert.show = true;
        })
        .catch((err) => {
          this.alert.message = `Se produjo un error en el cambio de defensor. ${err}`;
          this.alert.show = true;
          this.processing = false;
        });
    },
  },
  data() {
    return {
      processing: false,
      dialogConfirm: false,
      defender: "",
      comentarios: "",
      defendersRules: [(value) => !!value || "Requerido"],
      comentariosRules: [
        (value) => !!value || "Requerido",
        (value) => value.length <= 512 || "Demasiado largo",
        (value) =>
          this.allConstants.regex.regexComments.test(value) || "No es válido",
      ],
    };
  },
  beforeMount() {
    this.loadDefenders();
  },
};
</script>

<style lang="scss" scoped></style>
