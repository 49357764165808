<template>
  <div>
    <v-progress-linear
      indeterminate
      color="cyan"
      v-if="page.loading"
    ></v-progress-linear>
    <v-card class="pa-5" v-else>
      <v-row>
        <v-col cols="12" md="4" class="text-left"
          ><v-btn icon x-large @click="goBack()"
            ><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn
          >
          <SearchBack
            v-model="dialogBack"
            :dataSearch="currentClaimSelected.account.identificationNumber"
          />
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <div class="d-inline h3">
            Reclamo Nº
            {{ currentClaimSelected.claim.internalFolio }}
          </div>
          <Administration />
          <ChangeExecutive />
        </v-col>
        <v-col cols="12" md="4" class="text-right"
          ><v-chip>{{ lastActionCode | wfStatusFromAction }}</v-chip
          ><br />
          <v-btn color="primary" text @click="viewHistory">
            Ver Bitácora
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12"><DatesAndHistoryV2 /></v-col>
      </v-row>

      <v-row v-if="!isSolved">
        <v-col cols="12" md="12"><LastHumanEventV2 /></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6"><CustomerInfoV2 /></v-col>
        <v-col cols="12" md="6"><BankInfoV2 /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6"><FactInfoV2 /></v-col>
        <v-col cols="12" md="6"
          ><DocumentsInfo
            @approveDocument="approveDocument"
            @rejectDocument="rejectDocument"
            :parentIsProcessing="form.processing"
        /></v-col>
      </v-row>

      <v-row v-if="currentClaimSelected.claim.values.length > 0">
        <v-col cols="12" md="12"
          ><v-expansion-panels multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header
                ><span class="font-weight-bold d-block"
                  >Valores adicionales</span
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-chip
                  v-for="objValue in currentClaimSelected.claim.values"
                  :key="objValue.name"
                  class="ma-2"
                  color="primary"
                >
                  {{ objValue.name }}: {{ getNameValue(objValue) }}
                </v-chip>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels></v-col
        >
      </v-row>

      <v-row>
        <v-col cols="12" md="12"
          ><Actions
            :askComments="true"
            :parentIsProcessing="form.processing"
            @executeAction="executeAction"
        /></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12"
          ><ActionsPersonalized @confirm="goBack"
        /></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6" class="text-left"
          ><v-btn icon x-large @click="goBack()"
            ><v-icon x-large>mdi-arrow-left-circle</v-icon></v-btn
          ></v-col
        >
        <v-col cols="12" md="6" class="text-right">
          <v-btn color="primary" text @click="viewHistory">
            Ver Bitácora
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="alert.show" max-width="400" persistent>
      <v-card>
        <div class="text-right">
          <v-btn icon @click="alert.show = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div class="pa-6 pt-1">
          <div class="headline text-center mb-2">Atención</div>
          <div class="text-center mb-2">{{ alert.message }}</div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewProcessing" max-width="400" persistent>
      <v-card>
        <v-card-title>Procesando</v-card-title>

        <v-card-text>
          <v-row v-if="viewDocumentProcess">
            <v-col cols="12" md="6">Archivos procesados:</v-col>
            <v-col cols="12" md="6"
              >{{ documentProcess }} de {{ countDocument }}</v-col
            >
          </v-row>
          <v-row v-if="documentFailed > 0">
            <v-col cols="12" md="6">Archivos con errores:</v-col>
            <v-col cols="12" md="6">{{ documentFailed }}</v-col>
          </v-row>
          <v-progress-linear
            indeterminate
            reverse
            color="primary darken-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorProcessing" max-width="400" persistent>
      <v-card>
        <div class="text-right">
          <v-btn icon @click="errorProcessing = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-card-title>Error en el procesamiento de archivos</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">Archivos con errores:</v-col>
            <v-col cols="12" md="6">{{ documentFailed }}</v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="historyToView.show" max-width="1200" persistent>
      <v-card>
        <v-card-actions class="h4">
          <v-spacer />
          Bitácora del Reclamo Nº {{ currentClaimSelected.claim.internalFolio }}
          <v-spacer />
          <v-btn large icon @click="hideHistory()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-actions>
        <v-progress-linear
          indeterminate
          color="cyan"
          class="pa-2"
          v-if="historyToView.loading"
        ></v-progress-linear>
        <div v-else>
          <v-data-table
            :headers="eventsHeaders"
            :items="historyToView.events"
            :items-per-page="1000"
            hide-default-footer
            dense
            class="pa-2"
          >
            <template v-slot:[`item.actionCode`]="{ item }">
              {{ item.actionCode | wfStatusFromAction }}
            </template>
            <template v-slot:[`item.creationDate`]="{ item }" class="one-line">
              {{ item.creationDate | formatDateShort }}
              {{ item.creationDate | formatTimeShort }}
            </template>
            <template v-slot:[`item.participant.name`]="{ item }">
              <div v-if="item.participant.name.length <= 18">
                {{ item.participant.name }}
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="one-line" v-bind="attrs" v-on="on"
                      >{{ item.participant.name.substring(0, 18) }}...</span
                    >
                  </template>
                  <span>{{ item.participant.name }}</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.comments`]="{ item }">
              <div class="text-lowercase">{{ item.comments }}</div>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentDashboardMixin from "@/mixins/currentDashboard";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

import Administration from "@/components/WFSite/Claims/Administration";
import ChangeExecutive from "@/components/WFSite/Claims/Admin/ChangeExecutive";
import DatesAndHistoryV2 from "@/components/WFSite/Claims/DatesAndHistoryV2";
import CustomerInfoV2 from "@/components/WFSite/Claims/CustomerInfoV2";
import BankInfoV2 from "@/components/WFSite/Claims/BankInfoV2";
import FactInfoV2 from "@/components/WFSite/Claims/FactInfoV2";
import DocumentsInfo from "@/components/WFSite/Claims/DocumentsInfo";
import LastHumanEventV2 from "@/components/WFSite/Claims/LastHumanEventV2";

import Actions from "@/components/WFSite/Claims/Actions";
import ActionsPersonalized from "@/components/WFSite/Claims/ActionsPersonalized";

import SearchBack from "@/components/WFSite/SearchBack";

import claimsService from "@/services/claims";
import attachmentsService from "@/services/attachments";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentDashboardMixin,
    currentClaimSelectedMixin,
  ],
  components: {
    Administration,
    ChangeExecutive,
    DatesAndHistoryV2,
    CustomerInfoV2,
    BankInfoV2,
    FactInfoV2,
    DocumentsInfo,
    LastHumanEventV2,
    Actions,
    ActionsPersonalized,
    SearchBack,
  },
  data() {
    return {
      dialogBack: false,
      panel: [0],
      viewProcessing: false,
      viewDocumentProcess: false,
      errorProcessing: false,
      documentProcess: 0,
      countDocument: 0,
      documentFailed: 0,
      historyToView: {
        show: false,
        loading: false,
        events: [],
      },
      eventsHeaders: [
        { text: "Estado", value: "actionCode", sortable: false },
        { text: "Fecha", value: "creationDate", sortable: false },
        { text: "Rol", value: "participant.roleName", sortable: false },
        { text: "Nombre", value: "participant.name", sortable: false },
        { text: "Comentarios", value: "comments", sortable: false },
      ],
    };
  },
  computed: {
    lastActionCode() {
      return this.currentClaimSelected.claim.lastActionCode;
    },
    isSolved() {
      return this.allConstants.dashboard.groups.claimant.claimsSolved.statuses.includes(
        this.currentClaimSelected.claim.state
      );
    },
  },
  methods: {
    async approveDocument(documentId) {
      //ejecutar acción dependiendo si es aprobación en primera instancia (ejecutivo-ddc) o en segunda instancia (defensor-ddc)
      this.initSignalProcessing();

      let newDocumentStatus;
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcExecutive
      ) {
        newDocumentStatus = this.allConstants.claims.documentStatuses
          .approvedFirstInstance;
      }
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcDefender
      ) {
        newDocumentStatus = this.allConstants.claims.documentStatuses
          .approvedSecondInstance;
      }

      await claimsService.changeDocumentStatus(
        this.currentClaimSelected.claim.id,
        documentId,
        newDocumentStatus,
        {}
      );

      await this.reloadClaimForWf();

      this.stopSignalProcessing();
    },
    async rejectDocument(documentId) {
      //ejecutar acción dependiendo si es aprobación en primera instancia (ejecutivo-ddc) o en segunda instancia (defensor-ddc)
      this.initSignalProcessing();

      let newDocumentStatus;
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcExecutive
      ) {
        newDocumentStatus = this.allConstants.claims.documentStatuses
          .rejectedFirstInstance;
      }
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcDefender
      ) {
        newDocumentStatus = this.allConstants.claims.documentStatuses
          .rejectedSecondInstance;
      }

      await claimsService.changeDocumentStatus(
        this.currentClaimSelected.claim.id,
        documentId,
        newDocumentStatus,
        {}
      );

      await this.reloadClaimForWf();

      this.stopSignalProcessing();
    },
    async executeAction(
      actionCode,
      reasonCode,
      comments,
      documents,
      ddcAssignedUserId,
      bankAssignedUserId,
      posibleValues
    ) {
      const resultCheck = this.checkIfActionCanBeExecuted(
        actionCode,
        posibleValues
      );
      if (!resultCheck.canBe) {
        this.showAlert(resultCheck.errorMessage);
        return;
      }

      this.initSignalProcessing();
      this.viewProcessing = true;

      const documentsUploaded = [];

      if (documents && documents.length > 0) {
        this.documentProcess = 0;
        this.documentFailed = 0;
        this.countDocument = documents.length;
        this.viewDocumentProcess = true;
        await this.uploadDocuments(documents);

        documents.forEach((document) => {
          documentsUploaded.push({
            blobId: document.blobId,
            name: document.name,
            fileName: document.realName,
            contentType: document.fileContentType,
            contentLenght: document.fileSize.toString(),
          });
        });
      }

      if (this.documentFailed > 0) {
        this.stopSignalProcessing();
        this.viewProcessing = false;
        this.errorProcessing = true;
        return;
      }

      await this.changeWfStatusFromAction(
        actionCode,
        reasonCode,
        comments,
        documentsUploaded,
        ddcAssignedUserId,
        bankAssignedUserId,
        posibleValues
      );
      await this.loadDashboard();

      this.stopSignalProcessing();
      this.viewProcessing = false;
      this.goBack();
    },
    async uploadDocuments(documents) {
      for (let i = 0; i < documents.length; i++) {
        if (documents[i].blob != null) {
          documents[i].realName = documents[i].blob.name;
          documents[i].fileSize = documents[i].blob.size;
          documents[i].fileContentType = documents[i].blob.type;
          documents[i].internalId = this.$uuid.v4();

          documents[i].loading = true;

          const uploadResult = await this.uploadOneDocumentV2(
            documents[i].blob
          );

          documents[i].blobId = uploadResult.blobId;
          documents[i].loaded = uploadResult.loaded;

          documents[i].loading = false;
        }
      }
    },
    async uploadOneDocument(internalId, blob) {
      const addResult = await attachmentsService.add(blob);
      return {
        internalId: internalId,
        blobId: addResult.data.blobId,
      };
    },
    async uploadOneDocumentV2(blob) {
      const addResult = await attachmentsService.add(blob);
      if (addResult.status != 200) {
        this.documentFailed++;
        return {
          blobId: "00000000-0000-0000-0000-000000000000",
          loaded: false,
        };
      }

      this.documentProcess++;
      return {
        blobId: addResult.data.blobId,
        loaded: true,
      };
    },
    goBack() {
      console.log("goBack --> ", this.currentClaimSelected.fromRouteName);
      if (this.currentClaimSelected.fromRouteName == "dashboard") {
        //this.gotoView(this.currentClaimSelected.fromRouteName);
        this.dialogBack = true;
      } else this.gotoView(this.currentClaimSelected.fromRouteName);
    },
    getNameValue(objValue) {
      if (objValue.name == "Tipología de Reclamo") {
        const name = this.getTypologyName(objValue.value);
        if (name == undefined || name == null) return objValue.value;

        return name;
      }

      return objValue.value;
    },
    async viewHistory() {
      this.historyToView.loading = true;
      this.historyToView.show = true;
      this.historyToView.events = [];

      this.historyToView.events = await this.loadClaimHistory();
      this.historyToView.loading = false;
    },
    async hideHistory() {
      this.historyToView.loading = false;
      this.historyToView.show = false;
    },
  },
  beforeMount() {
    this.initSignalLoading();
    this.stopSignalLoading();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
</style>
