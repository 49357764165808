<template>
  <v-card flat>
    <v-card-title>Eventos de Administración</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="data"
        :loading="processing"
        loading-text="Cargando eventos"
        no-data-text="No hay eventos de administración"
        :items-per-page="100"
        hide-default-footer
        dense
        show-expand
        item-key="id"
        :single-expand="true"
        :expanded.sync="expanded"
      >
        <template v-slot:[`item.type`]="{ item }">
          {{ getNameAction(item.type) }}
        </template>
        <template v-slot:[`item.creationDate`]="{ item }">
          {{ item.creationDate | formatDateShort }}
          {{ item.creationDate | formatTimeShort }}
        </template>
        <template v-slot:[`item.userId`]="{ item }">
          {{ getNameUser(item.userId) }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row v-if="item.type == 'GoBackOneState'" class="ma-2">
              <v-col cols="12" md="4">
                Estado Anterior:
                {{
                  wfStatusFronState(
                    item.detail.currentEventDeleted[0].StateCode
                  )
                }}
              </v-col>
              <v-col cols="12" md="4">
                Nuevo Estado:
                {{ wfStatusFronState(item.detail.NewStateCode) }}
              </v-col>
            </v-row>
            <v-row
              v-if="
                item.type == 'ChangeDefender' || item.type == 'ChangeExecutive'
              "
              class="ma-2"
            >
              <v-col cols="12" md="6">
                Usuario Anterior:
                {{ getNameUser(item.detail.OldUserId) }}
              </v-col>
              <v-col cols="12" md="6">
                Usuario Nuevo:
                {{ getNameUser(item.detail.NewUserId) }}
              </v-col>
            </v-row>
            <v-row v-if="item.type == 'AttachDocument'" class="ma-2">
              <v-col cols="12" md="6">
                <pre>{{ item.detail }}</pre>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <Alert
        :dialog="alert.show"
        :msj="alert.message"
        @close="alert.show = false"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import serviceAdmin from "@/services/admin";
import Alert from "@/components/Alert";
import accountsService from "@/services/accounts";

export default {
  mixins: [baseViewMixin, currentClaimSelectedMixin],
  components: {
    Alert,
  },
  data() {
    return {
      processing: false,
      expanded: [],
      headers: [
        { text: "Acción", value: "type" },
        { text: "Fecha", value: "creationDate" },
        { text: "Usuario", value: "userId" },
        { text: "Comentario", value: "comments" },
        { text: "", value: "data-table-expand" },
      ],
      data: [],
      accounts: [],
      states: [],
    };
  },
  methods: {
    wfStatusFronState(value) {
      if (value == null) return null;
      const state = this.states.find((s) => s.code == value);
      if (state == undefined || state == null) return value;

      return state.name;
    },
    getNameAction(value) {
      switch (value) {
        case "AttachDocument":
          return "Documento Agregado";
        case "GoBackOneState":
          return "Volver un Estado Atrás";
        case "ChangeDefender":
          return "Cambio de Defensor";
        case "ChangeExecutive":
          return "Cambio de Ejecutivo Banco";
        default:
          return value;
      }
    },
    getNameUser(id) {
      const account = this.accounts.find((a) => a.id == id);
      if (account == undefined || account == null) {
        accountsService
          .getById(id)
          .then((result) => {
            this.accounts.push(result.data.account);
            return result.data.account.name;
          })
          .catch(() => {
            return id;
          });
      } else return account.name;
    },
    loadEvent() {
      this.processing = true;
      serviceAdmin
        .eventLog(this.currentClaimSelected.claim.id)
        .then((result) => {
          this.processing = false;
          this.data = result.data.claimEventLogs;
          this.accounts = result.data.accounts;
        })
        .catch((err) => {
          this.alert.message = `Se produjo un error en el cambio de defensor. ${err}`;
          this.alert.show = true;
          this.processing = false;
        });
    },
    dummy() {
      for (let i = 1; i < 6; i++) {
        this.data.push({
          id: i,
          type: `AAAA ${i}`,
          date: `BBBB ${i}`,
          user: `CCCC ${i}`,
          comment: `DDDD ${i}`,
        });
      }
    },
  },
  beforeMount() {
    const parametersStates = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStatuses"
    );
    this.states = parametersStates.values;

    this.loadEvent();
  },
};
</script>

<style lang="scss" scoped></style>
