<template>
  <v-card flat>
    <v-card-title>Añadir un Archivo al Reclamo</v-card-title>
    <v-card-text>
      <v-form v-model="form.isValid" @submit.prevent="enter">
        <v-row>
          <v-col cols="12" md="9">
            <v-textarea
              outlined
              label="Comentario"
              placeholder="Ingrese un comentario"
              v-model="comentarios"
              :rules="comentariosRules"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              autocomplete="false"
              label="Nombre Documento"
              placeholder="Ingrese el nombre del documento"
              outlined
              v-model.trim="documento.name"
              :disabled="page.loading"
              :rules="comentariosRules"
              required
              maxlength="25"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-file-input
              v-model="documento.blob"
              truncate-length="40"
              :clearable="true"
              :disabled="page.loading"
              :rules="documentosRequeridosRules"
              outlined
              label="Archivo"
            >
            </v-file-input>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              color="indigo"
              class="white--text"
              type="submit"
              :disabled="!form.isValid"
              :loading="page.loading"
              x-large
              >Adjuntar Documento</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <Alert
      :dialog="alert.show"
      :msj="alert.message"
      @close="goBack"
      :dialogPersistent="true"
    />
  </v-card>
</template>

<script>
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import baseViewMixin from "@/mixins/baseView";

import Alert from "@/components/Alert";

import attachmentsService from "@/services/attachments";
import serviceAdmin from "@/services/admin";

export default {
  mixins: [currentClaimSelectedMixin, baseViewMixin],
  components: {
    Alert,
  },
  data() {
    return {
      documento: {
        blob: null,
        name: "",
      },
      documentosRequeridosRules: [
        (value) => !!value || "Requerido",
        (value) =>
          this.isValidFileName(value) ||
          "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
        (value) => this.isValidFileSize(value) || "Tamaño máximo 10 Mb",
      ],
      comentarios: "",
      comentariosRules: [
        (value) => !!value || "Requerido",
        (value) => value.length <= 512 || "Demasiado largo",
        (value) =>
          this.allConstants.regex.regexComments.test(value) || "No es válido",
      ],
    };
  },
  methods: {
    goBack() {
      this.gotoView(this.currentClaimSelected.fromRouteName);
    },
    enter() {
      this.page.loading = true;

      this.uploadOneDocument(this.documento.blob)
        .then((result) => {
          if (result == undefined || result == null || result.loaded == false) {
            this.alert.message = `Se produjo un error al cargar el archivo.`;
            this.alert.show = true;
            this.page.loading = false;
          }

          const data = {
            blobId: result.blobId,
            name: this.documento.name,
            fileName: this.documento.blob.name,
            contentType: this.documento.blob.type,
            contentLenght: this.documento.blob.size.toString(),
            comments: this.comentarios,
          };

          serviceAdmin
            .attachDocument(this.currentClaimSelected.claim.id, data)
            .then(() => {
              this.processing = false;
              this.alert.message = "El documento se subio exitosamente";
              this.alert.show = true;
              this.page.loading = false;
            })
            .catch((err) => {
              this.alert.message = `Se produjo un error al cargar el archivo. ${err}`;
              this.alert.show = true;
              this.page.loading = false;
            });
        })
        .catch((err) => {
          this.alert.message = `Se produjo un error al cargar el archivo. ${err}`;
          this.alert.show = true;
          this.page.loading = false;
        });
    },
    isValidFileName(fileInfo) {
      if (fileInfo == null) return true;

      return this.allConstants.regex.regexFileNameComplex.test(fileInfo.name);
    },
    isValidFileSize(fileInfo) {
      if (fileInfo == null) return true;

      return fileInfo.size <= 10485760; //10 mg segun requerimiento como tamaño maximo
    },
    async uploadOneDocument(blob) {
      return new Promise((resolve, reject) => {
        attachmentsService
          .add(blob)
          .then((result) => {
            resolve({
              blobId: result.data.blobId,
              loaded: true,
            });
          })
          .catch((err) => {
            reject({
              blobId: "00000000-0000-0000-0000-000000000000",
              loaded: false,
              err,
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
