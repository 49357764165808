<template>
  <v-card flat>
    <v-card-title>Retroceder un Estado del Reclamo </v-card-title>
    <v-card-text>
      <v-alert prominent type="warning">
        <v-row align="center">
          <v-col class="grow">
            Retroceder un estado del reclamo tiene los siguientes riesgos:
            <ul>
              <li>
                El estado del reclamo vuelve al estado anterior y la última
                acción que realizó el usuario se desactivará.
              </li>
              <li>
                La información eliminada de la última acción quedará registrada
                en los eventos de administración.
              </li>
              <li>
                Los archivos subidos en la acción eliminada NO serán borrados
                del sistema y podrán seguir siendo descargados.
              </li>
              <li>
                Tener en cuenta que, al retroceder un estado, este puede tener
                asociado un cambio automático, por lo que el motor de SLA podría
                volver atrás su acción, ya que este se ejecuta cada 5 minutos.
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-alert>

      <v-alert prominent type="error" v-if="showBtnBack">
        <v-row align="center">
          <v-col class="grow">
            <div class="text-center h2">
              {{ lastState | wfStatusFromAction }}
              <v-icon x-large color="black">
                mdi-arrow-right-bold-outline
              </v-icon>
              {{ wfStatusFronState(previousState) }}
            </div>
          </v-col>
          <v-col class="shrink">
            <v-btn color="indigo" @click="confirm">Retroceder estado</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>

    <v-dialog v-model="dialogConfirm" persistent width="500">
      <v-card>
        <v-card-title class="white--text text-h5 red lighten-2">
          ¿Está seguro que desea continuar?
        </v-card-title>
        <v-form v-model="form.isValid" @submit.prevent="enter">
          <v-card-text class="mt-3">
            <v-textarea
              outlined
              label="Comentario"
              placeholder="Ingrese un comentario"
              v-model="comentarios"
              :rules="comentariosRules"
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" text @click="dialogConfirm = false">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              text
              :disabled="!form.isValid || page.loading"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <Processing :viewProcessing="processing" title="Procesando..." />
    <Alert
      :dialog="alert.show"
      :msj="alert.message"
      @close="goBack"
      :dialogPersistent="true"
    />
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import Processing from "@/components/Processing";
import Alert from "@/components/Alert";

import serviceAdmin from "@/services/admin";

export default {
  mixins: [baseViewMixin, currentClaimSelectedMixin],
  components: {
    Processing,
    Alert,
  },
  computed: {
    showBtnBack() {
      if (
        this.previousState == null ||
        this.previousState == "PENDIENTE-ADMISION" ||
        this.previousState == "RECHAZADO-FORMA" ||
        this.previousState == "PENDIENTE-DEFENSOR" ||
        this.previousState == "PRESENTADO-BANCO-DEFENSOR" ||
        this.previousState == "ANTECEDENTES-ADICIONALES-CLIENTE" ||
        this.previousState == "ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE" ||
        this.previousState == "ANTECEDENTES-ADICIONALES-BANCO" ||
        this.previousState == "ANTECEDENTES-ADICIONALES-PARALELO-BANCO"
      )
        return false;

      return true;
    },
    lastEvent() {
      if (this.currentClaimSelected.claim.events.length == 0) return null;
      return this.currentClaimSelected.claim.events[
        this.currentClaimSelected.claim.events.length - 1
      ];
    },
    lastState() {
      if (this.lastEvent == null || this.lastEvent.actionCode == "")
        return null;

      return this.lastEvent.actionCode;
    },
    previousState() {
      if (this.lastEvent == null || this.lastEvent.stateCode == "") return null;

      return this.lastEvent.stateCode;
    },
  },
  methods: {
    goBack() {
      this.gotoView(this.currentClaimSelected.fromRouteName);
    },
    enter() {
      this.dialogConfirm = false;
      this.processing = true;

      const data = {
        comments: this.comentarios,
      };

      serviceAdmin
        .goBackOneState(this.currentClaimSelected.claim.id, data)
        .then(() => {
          this.processing = false;
          this.alert.message = "El cambio se realizó exitosamente";
          this.alert.show = true;
        })
        .catch((err) => {
          this.alert.message = `Se produjo un error en el cambio de estado. ${err.response.data.reason}`;
          this.alert.show = true;
          this.processing = false;
        });
    },
    confirm() {
      this.dialogConfirm = true;
      this.comentarios = "";
    },
    wfStatusFronState(value) {
      if (value == null) return null;
      const state = this.states.find((s) => s.code == value);
      if (state == undefined || state == null) return value;

      return state.name;
    },
  },
  data() {
    return {
      processing: false,
      dialogConfirm: false,
      states: [],
      comentarios: "",
      comentariosRules: [
        (value) => !!value || "Requerido",
        (value) => value.length <= 512 || "Demasiado largo",
        (value) =>
          this.allConstants.regex.regexComments.test(value) || "No es válido",
      ],
    };
  },
  beforeMount() {
    const parametersStates = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStatuses"
    );
    this.states = parametersStates.values;
  },
};
</script>

<style lang="scss" scoped></style>
