<template>
  <v-card
    color="#EEF5F7"
    class="pa-1 ma-1"
    v-if="currentClaimSelected.lastHumanEvent"
    elevation="2"
    height="100%"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="h5">Última acción del usuario</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" class="text-center">
          <span class="d-block font-weight-medium">{{
            getRoleName(currentClaimSelected.lastHumanEvent.rol)
          }}</span>
          <span class="">{{
            currentClaimSelected.lastHumanEvent.userName
          }}</span>
        </v-col>
        <v-col cols="12" md="3" class="text-center">
          <span class="d-block font-weight-medium">Fecha</span>
          <span class="">{{
            currentClaimSelected.lastHumanEvent.creationDate | formatDateShort
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <span class="d-block font-weight-medium">Comentario</span>
          <span class="text-justify text-lowercase">{{
            currentClaimSelected.lastHumanEvent.comments
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
};
</script>

<style lang="scss" scoped></style>
