var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.posibleActions.length > 0)?_c('v-card',{staticClass:"pa-1 ma-1",attrs:{"height":"100%","elevation":"0"}},[_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.enter($event)}},model:{value:(_vm.form.isValid),callback:function ($$v) {_vm.$set(_vm.form, "isValid", $$v)},expression:"form.isValid"}},[(_vm.askComments)?_c('div',[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 mb-2"},[_vm._v(" Comentarios ")]),_c('v-textarea',{attrs:{"required":"","rules":_vm.formFields.comentariosRules,"disabled":_vm.parentIsProcessing,"maxlength":"512","rows":"3","outlined":""},model:{value:(_vm.formFields.comentarios),callback:function ($$v) {_vm.$set(_vm.formFields, "comentarios", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formFields.comentarios"}})],1):_vm._e(),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 mb-2"},[_vm._v(" Acción ")]),_c('v-select',{attrs:{"outlined":"","items":_vm.posibleActions.map(function (action) {
                  return { value: action.action, text: action.name };
                }),"placeholder":"Seleccione","required":"","rules":_vm.formFields.accionRules,"disabled":_vm.posibleActions.length == 0 || _vm.parentIsProcessing},on:{"change":function($event){return _vm.prepareOtherData()}},model:{value:(_vm.formFields.accion),callback:function ($$v) {_vm.$set(_vm.formFields, "accion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formFields.accion"}})],1),(_vm.posibleReasons.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 mb-2"},[_vm._v(" Motivo ")]),_c('v-select',{attrs:{"outlined":"","items":_vm.posibleReasons.map(function (reason) {
                  return { value: reason.code, text: reason.name };
                }),"placeholder":"Seleccione","required":"","rules":_vm.formFields.razonAccionRules,"disabled":_vm.posibleReasons.length == 0 || _vm.parentIsProcessing},model:{value:(_vm.formFields.razonAccion),callback:function ($$v) {_vm.$set(_vm.formFields, "razonAccion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formFields.razonAccion"}})],1):_vm._e(),(_vm.posibleValues.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 mb-2"},[_vm._v(" Valores solicitados ")]),_vm._l((_vm.posibleValues),function(value){return _c('div',{key:value.name},[(value.type == 'Number')?_c('v-text-field',{attrs:{"outlined":"","required":value.required,"maxlength":value.length,"label":value.name,"rules":_vm.validateRules(value)},model:{value:(value.input),callback:function ($$v) {_vm.$set(value, "input", $$v)},expression:"value.input"}}):_vm._e(),(value.type == 'Drop')?_c('v-select',{attrs:{"outlined":"","items":_vm.posibleValuesDrop(value),"placeholder":value.name,"required":"","rules":_vm.validateRules(value),"disabled":_vm.parentIsProcessing},model:{value:(value.input),callback:function ($$v) {_vm.$set(value, "input", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.input"}}):_vm._e()],1)})],2):_vm._e(),(_vm.formFields.documentosRequeridos.length > 0)?_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 mb-2"},[_vm._v(" Documentos requeridos ")]),_c('table',{staticClass:"table table-striped",staticStyle:{"width":"100%"}},[_c('tbody',_vm._l((_vm.formFields.documentosRequeridos),function(documento){return _c('tr',{key:documento.name},[_c('td',[_vm._v(" "+_vm._s(documento.name)+" "+_vm._s(documento.required ? "(*)" : "")+" ")]),_c('td',[_c('v-file-input',{staticClass:"ma-0 pa-0",attrs:{"accept":documento.allowedFileTypes.join(','),"truncate-length":"20","clearable":true,"rules":documento.required
                          ? _vm.formFields.documentosRequeridosRules
                          : _vm.formFields.documentosAlternativosRules,"disabled":_vm.form.processing},on:{"change":function($event){return _vm.setLastSelectedDocument(documento)}},model:{value:(documento.blob),callback:function ($$v) {_vm.$set(documento, "blob", $$v)},expression:"documento.blob"}})],1),_c('td',[(documento.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"blue-gre"}}):_vm._e()],1)])}),0)])]):_vm._e(),(_vm.ddcDefenderIsRequired)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 mb-2"},[_vm._v(" Defensor ")]),_c('v-select',{attrs:{"outlined":"","items":_vm.posibleDDCDefenders.map(function (defensor) {
                  return { value: defensor.id, text: defensor.name };
                }),"placeholder":"Seleccione","required":"","rules":_vm.formFields.defensorDDCRules,"disabled":_vm.posibleDDCDefenders.length == 0 || _vm.parentIsProcessing},model:{value:(_vm.formFields.defensorDDC),callback:function ($$v) {_vm.$set(_vm.formFields, "defensorDDC", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formFields.defensorDDC"}})],1):_vm._e(),(_vm.bankExecutiveIsRequired)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 mb-2"},[_vm._v(" Ejecutivo ")]),_c('v-select',{attrs:{"outlined":"","items":_vm.posibleBankExecutives.map(function (executive) {
                  return { value: executive.id, text: executive.name };
                }),"placeholder":"Seleccione","required":"","rules":_vm.formFields.ejecutivoBancoRules,"disabled":_vm.posibleBankExecutives.length == 0 || _vm.parentIsProcessing},model:{value:(_vm.formFields.ejecutivoBanco),callback:function ($$v) {_vm.$set(_vm.formFields, "ejecutivoBanco", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formFields.ejecutivoBanco"}})],1):_vm._e()],1)],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mr-3 text-white",attrs:{"type":"submit","color":"green","disabled":!_vm.form.isValid || _vm.page.loading,"loading":_vm.parentIsProcessing}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" Grabar")],1)],1)]),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-2"},[_vm._v(" ¿Está seguro de realizar esta acción? ")]),_c('v-card-text',{staticClass:"text-center text-h5"},[_c('br'),_vm._v(" "+_vm._s(_vm.nameActions(_vm.formFields.accion))+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secundary","text":""},on:{"click":function($event){_vm.dialogConfirm = false}}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.enterConfirm}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }