<template>
  <v-card height="100%" class="pa-1 ma-1" elevation="2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="h5">Información del banco</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5" class="text-center">
          <span class="font-weight-bold d-block">Banco</span>
          <span class="text-capitalize">{{
            getBankName(currentClaimSelected.claim.bank.code)
          }}</span>
        </v-col>
        <v-col cols="12" md="7" class="text-center">
          <span class="font-weight-bold d-block">Sucursal</span>
          <span class="text-lowercase">
            {{ getBankName(currentClaimSelected.claim.bank.branch) }},
            {{
              getCommuneName(
                currentClaimSelected.claim.bank.region,
                null,
                currentClaimSelected.claim.bank.commune
              )
            }},
            {{ getRegionName(currentClaimSelected.claim.bank.region) }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <span class="font-weight-bold d-block">Ejecutivo</span>
          <span class="text-capitalize">{{
            currentClaimSelected.claim.bank.executiveName
          }}</span
          ><br />
          <span class="text-lowercase">{{
            currentClaimSelected.claim.bank.executiveEmail
          }}</span
          ><br />
          <span class="text-lowercase"
            >+56 9 {{ currentClaimSelected.claim.bank.executivePhone }}</span
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
};
</script>

<style lang="scss" scoped></style>
