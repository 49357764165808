<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="showChange"
        @click="clean"
      >
        Cambiar Ejecutivo
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="white--text red lighten-1">
        Se dispone a cambiar al ejecutivo asignado.
      </v-card-title>
      <v-card-subtitle class="mt-3">
        Ejecutivo Actual es
        {{ nombreEjecutivoActual }}
      </v-card-subtitle>
      <v-form v-model="form.isValid" @submit.prevent="enter">
        <v-card-text>
          <v-select
            outlined
            v-model.trim="ejecutivoBanco"
            :items="this.posibleBankExecutives"
            label="Ejecutivo"
            placeholder="Seleccione un Ejecutivo"
            required
            :rules="ejecutivoBancoRules"
            :disabled="processing"
          ></v-select>
          <v-textarea
            outlined
            label="Comentario"
            placeholder="Ingrese un comentario"
            v-model="comentarios"
            :rules="comentariosRules"
            :disabled="processing"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            text
            :disabled="!form.isValid || page.loading"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <Processing :viewProcessing="processing" title="Procesando..." />
    <Alert
      :dialog="alert.show"
      :msj="alert.message"
      @close="goBack"
      :dialogPersistent="true"
    />
  </v-dialog>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import currentUserMixin from "@/mixins/currentUser";
import parameters from "@/mixins/parameters";

import Processing from "@/components/Processing";
import Alert from "@/components/Alert";

import serviceAdmin from "@/services/admin";
import accountsService from "@/services/accounts";

export default {
  mixins: [
    baseViewMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
    parameters,
  ],
  components: {
    Processing,
    Alert,
  },
  computed: {
    showChange() {
      if (
        this.currentUserInfo.roles[0] ==
          this.allConstants.security.userRolesCodes.bankAdministrator ||
        this.currentUserInfo.roles[0] ==
          this.allConstants.security.userRolesCodes.ddcExecutive
      ) {
        if (
          this.currentClaimSelected.claim.userIdBank ==
          "00000000-0000-0000-0000-000000000000"
        )
          return false;

        if (this.currentClaimSelected.currentEvent == null) return false;

        return true;
      }

      return false;
    },
    nombreEjecutivoActual() {
      const executive = this.posibleBankExecutives.find(
        (executive) =>
          executive.value == this.currentClaimSelected.claim.userIdBank
      );

      if (executive != undefined || executive != null) return executive.text;

      return "";
    },
  },
  methods: {
    goBack() {
      this.gotoView(this.currentClaimSelected.fromRouteName);
    },
    clean() {
      this.ejecutivoBanco = "";
      this.comentarios = "";
    },
    enter() {
      this.processing = true;

      const data = {
        newUserId: this.ejecutivoBanco,
        comments: this.comentarios,
      };

      serviceAdmin
        .changeExecutive(this.currentClaimSelected.claim.id, data)
        .then(() => {
          this.processing = false;
          this.alert.message = "El cambio se realizó exitosamente";
          this.alert.show = true;
        })
        .catch((err) => {
          this.alert.message = `Se produjo un error en el cambio de ejecutivo. ${err}`;
          this.alert.show = true;
          this.processing = false;
        });
    },
    preparePosibleBankExecutives() {
      this.processing = true;
      this.posibleBankExecutives = [];
      this.ejecutivoBanco = "";

      accountsService
        .getByRole(this.allConstants.security.userRolesCodes.bankExecutive)
        .then((getByRoleResult) => {
          getByRoleResult.data.accounts.forEach((account) => {
            if (
              this.currentUserInfo.roles[0] ==
              this.allConstants.security.userRolesCodes.ddcExecutive
            ) {
              if (this.currentClaimSelected.claim.bank.code == account.code)
                this.posibleBankExecutives.push({
                  value: account.id,
                  text: `${account.name} ${account.surname}`,
                });
            } else {
              this.posibleBankExecutives.push({
                value: account.id,
                text: `${account.name} ${account.surname}`,
              });
            }
          });
          this.processing = false;
        })
        .catch((err) => {
          this.alert.message = `Se produjo un error al cargar los ejecutivos. ${err}`;
          this.alert.show = true;
          this.processing = false;
        });
    },
  },
  data() {
    return {
      processing: false,
      dialog: false,
      ejecutivoBanco: "",
      posibleBankExecutives: [],
      comentarios: "",
      ejecutivoBancoRules: [(value) => !!value || "Requerido"],
      comentariosRules: [
        (value) => !!value || "Requerido",
        (value) => value.length <= 512 || "Demasiado largo",
        (value) =>
          this.allConstants.regex.regexComments.test(value) || "No es válido",
      ],
    };
  },
  beforeMount() {
    this.preparePosibleBankExecutives();
  },
};
</script>

<style lang="scss" scoped></style>
