<template>
  <div>
    <div v-if="showAvenimientoPendienteCliente" class="text-center">
      <v-btn
        type="submit"
        color="green"
        class="mr-3 text-white"
        @click="dialogAvenimiento = true"
        >Finalizar Avenimiento</v-btn
      >
    </div>

    <v-dialog v-model="dialogAvenimiento" width="600" persistent>
      <v-card>
        <v-card-subtitle class="grey lighten-2 pt-2 mb-0 pb-0">
          ¿Está seguro de realizar esta acción?</v-card-subtitle
        >
        <v-card-title class="grey lighten-2 pt-0 pm-0"
          >Avenimiento Finalizado por Defensor</v-card-title
        >
        <v-card-text class="pt-2">
          <v-textarea
            v-model.trim="comentarios"
            label="Comentario"
            required
            maxlength="512"
            rows="3"
            outlined
            :disabled="page.loading"
            :loading="page.loading"
          ></v-textarea>
          <v-file-input
            label="Archivo 1"
            v-model="file1"
            :accept="allowedRequiredFileTypes"
            truncate-length="20"
            :clearable="true"
            class="ma-0 pa-0"
            :disabled="page.loading"
            :loading="page.loading"
          >
          </v-file-input>
          <v-file-input
            label="Archivo 2"
            v-model="file2"
            :accept="allowedRequiredFileTypes"
            truncate-length="20"
            :clearable="true"
            class="ma-0 pa-0"
            :disabled="page.loading"
            :loading="page.loading"
          >
          </v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secundary"
            text
            @click="dialogAvenimiento = false"
            :disabled="page.loading"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="enterConfirm"
            :loading="page.loading"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import claimsService from "@/services/claims";
import attachmentsService from "@/services/attachments";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentClaimSelectedMixin],
  methods: {
    async enterConfirm() {
      this.page.loading = true;

      const documentsUploaded = [];

      if (this.file1 != null) {
        const result1 = await this.uploadOneDocument(this.file1);
        documentsUploaded.push({
          blobId: result1.blobId,
          name: "Archivo 1",
          fileName: this.file1.name,
          contentType: this.file1.type,
          contentLenght: this.file1.size.toString(),
        });
      }

      if (this.file2 != null) {
        const result2 = await this.uploadOneDocument(this.file2);
        documentsUploaded.push({
          blobId: result2.blobId,
          name: "Archivo 2",
          fileName: this.file2.name,
          contentType: this.file2.type,
          contentLenght: this.file2.size.toString(),
        });
      }

      const actionContent = {
        reasonCode: "",
        comments: this.comentarios,
        documents: documentsUploaded,
        values: [],
        UserIdDDC: "",
        UserIdBank: "",
      };

      const currentWfStatus = this.getCurrentWfStatus();
      const actionCode = this.getActionCode();

      claimsService
        .changeWfStatusFromActionPersonalized(
          this.currentClaimSelected.claim.id,
          currentWfStatus,
          actionCode,
          actionContent
        )
        .then(() => {
          this.page.loading = false;
          this.dialogAvenimiento = false;
          this.$emit("confirm");
        })
        .catch((err) => {
          this.page.loading = false;
          this.$store.dispatch(
            "notifications/addError",
            `Servicio no disponible. Vuelva a intentar - ${err}`
          );
        });
    },
    async uploadOneDocument(blob) {
      const addResult = await attachmentsService.add(blob);
      if (addResult.status != 200) {
        return {
          blobId: "00000000-0000-0000-0000-000000000000",
          loaded: false,
        };
      }

      return {
        blobId: addResult.data.blobId,
        loaded: true,
      };
    },
    isValidFileName(fileInfo) {
      if (fileInfo == null) return true;

      return this.allConstants.regex.regexFileNameComplex.test(fileInfo.name);
    },
    isValidAlternativeFileType(fileInfo) {
      if (fileInfo == null) return true;

      return (
        this.allowedAlternativesFileTypes.filter(
          (fileType) => fileType == fileInfo.type
        ).length > 0
      );
    },
    getCurrentWfStatus() {
      if (
        this.currentClaimSelected.claim.lastStateCode ==
        "AVENIMIENTO-PENDIENTE-CLIENTE"
      )
        return this.currentClaimSelected.claim.lastStateCode;

      return "";
    },
    getActionCode() {
      if (
        this.currentClaimSelected.claim.lastStateCode ==
        "AVENIMIENTO-PENDIENTE-CLIENTE"
      )
        return "ACEPTAR-AVENIMIENTO-DEFENSOR";

      return "";
    },
  },
  computed: {
    showAvenimientoPendienteCliente() {
      if (
        this.currentClaimSelected.claim.lastStateCode ==
          "AVENIMIENTO-PENDIENTE-CLIENTE" &&
        (this.currentUserInfo.roles[0] ==
          this.allConstants.security.userRolesCodes.ddcExecutive ||
          this.currentUserInfo.roles[0] ==
            this.allConstants.security.userRolesCodes.ddcDefender)
      )
        return true;

      return false;
    },
  },
  data() {
    return {
      dialogAvenimiento: false,
      comentarios: "",
      file1: null,
      file2: null,
      documentAlternativeRules: [
        (value) =>
          this.isValidFileName(value) ||
          "El nombre contiene caracteres inválidos. Solo letras, números, puntos y guiones, y sin espacios.",
        (value) =>
          this.isValidAlternativeFileType(value) || "Formato incorrecto",
      ],
      allowedRequiredFileTypes: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "image/gif",
        "image/jpeg",
        "image/tiff",
        "image/svg+xml",
        "image/png",
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
